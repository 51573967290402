import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { CookieService } from 'ngx-cookie-service';
import { lastValueFrom } from 'rxjs';

import { IMember } from '@mapuilabs/mpl-interfaces';

import { TranslocoService } from '@jsverse/transloco';
import { Utils } from '@core/services/Utils';
import { HospitalService } from '../services/hospital/hospital.service';
import { PermissionService } from '../services/permission/permission.service';
import dayjs from 'dayjs';

@Injectable({
    providedIn: 'root'
})
export class Auth {
    /**
     * Current user
     */
    public user: IMember;

    constructor(
        private _cookieService: CookieService,
        private _httpClient: HttpClient,
        private _hospitalService: HospitalService,
        private _permissionService: PermissionService,
        private _translocoService: TranslocoService
    ) {
        const userCookie = this._cookieService.get('user');

        this.changeUser(userCookie ? JSON.parse(userCookie) : {});
    }

    /*
     * Check if user is logged in and load his permissions
     */
    async check(): Promise<boolean> {
        // if no user cookie, ciao
        if (Object.keys(this.user).length === 0) {
            return false;
        }

        // if need first-connection or reset-password
        if (this.isNotActivated()) {
            return false;
        }

        // load permissions
        try {
            await this._permissionService.updateUserPermissions();
        } catch (error) {
            console.log('error', error);
            if (error?.status === 403) {
                await this.logout();
                return false;
            }
        }

        this.user.hospital = await lastValueFrom(this._hospitalService.getById(Utils.getId(this.user.hospital)));

        return true;
    }

    public isLogged(): boolean {
        return Utils.hasId(this.user) && !!this.user.hospital;
    }

    public isNotActivated(): boolean {
        return this.isLogged() && !!this.user.activation;
    }

    public CGUisAccepted(): boolean {
        return this.isLogged() && !!this.user.acceptedCGU;
    }

    // request

    public async forgotPassword(user: { email: string }): Promise<void> {
        await lastValueFrom(this._httpClient.post('/forgot-password', user, { responseType: 'text' }));
    }

    public async changePassword(_id: string, body: { password: string; oldPwd?: string }): Promise<void> {
        const res = await lastValueFrom(this._httpClient.put<IMember>(`/change-password/${_id}`, body));
        this.changeUser(res, true);
    }

    public async login(user: { username: string; password: string }): Promise<IMember | { token: string }> {
        const res = await lastValueFrom(this._httpClient.post<{ token: string } | IMember>(`/login`, user));

        if (!(res as { token: string })?.token) {
            this.changeUser(res as IMember, true);
        }
        return res;
    }

    public async logout() {
        await lastValueFrom(this._httpClient.post('/logout', null, { responseType: 'text' }));

        // redirect to /auth/login and reload window to clean auth
        window.location.replace('/auth/login');
    }

    public updateUserCookie(user: IMember) {
        this._cookieService.set('user', JSON.stringify(user), { path: '/' });
    }

    private changeUser(user: IMember, setCookie = false) {
        this.user = user;
        if (this.user?.config?.language) {
            this._translocoService.setActiveLang(this.user.config.language);
            dayjs.locale(this.user.config.language);
        }
        if (setCookie) {
            this.updateUserCookie(user);
        }
    }

    public getValidatorFnPassword(): ValidatorFn {
        const passwordPatternValidator = (pattern: RegExp, errorName: ValidationErrors): ValidatorFn => {
            return (control: AbstractControl): ValidationErrors | null =>
                pattern.test(control.value) ? null : errorName;
        };

        return Validators.compose([
            Validators.required,
            //has number
            passwordPatternValidator(/\d/, { hasNumber: true }),
            //has lowercase characters
            passwordPatternValidator(/[a-z]/, { hasLowercase: true }),
            //has uppercase characters
            passwordPatternValidator(/[A-Z]/, { hasUppercase: true }),
            //Use of regex instead of angular Validators.minLength() thus error is triggered when input is empty and untouched
            passwordPatternValidator(/.{8,}/, { hasEightCharMin: true })
        ]);
    }
}
